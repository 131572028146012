import { atom, useRecoilValue } from 'recoil';

const atoms: Record<string, any> = {};

export const editorStore = (key: string, defaultValue: string = '') => {
  if (!atoms[key]) {
    atoms[key] = atom({
      key,
      default: defaultValue,
    });
  }
  return atoms[key];
};

export const useEditorByEmployeeId = (employeeId: number): string => {
  const employeeAtom = editorStore(`${employeeId}`, '');
  const result = useRecoilValue(employeeAtom);
  return result as string;
};

import { Configuration, PublicClientApplication } from '@azure/msal-browser';

import { env } from './env';

export const msalConfig: Configuration = {
  auth: {
    clientId: env.REACT_APP_AZURE_CLIENT_ID ?? '',
    authority: 'https://login.microsoftonline.com/e3183c89-78ae-43e0-b8a3-3eb012e5d9ea',
    redirectUri: '/',
    clientCapabilities: ['CP1'],
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['User.Read', 'Mail.Send'],
};

export const apiConfig = {
  scopes: [`api://${env.REACT_APP_AZURE_CLIENT_ID}/access_as_user`],
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com',
};

const msalInstance = new PublicClientApplication(msalConfig);

export async function getAccessToken(): Promise<string | null> {
  try {
    const response = await msalInstance.acquireTokenSilent({
      scopes: ['Mail.Send'],
    });

    return response.accessToken;
  } catch (error) {
    if (error) {
      try {
        const response = await msalInstance.acquireTokenPopup({
          scopes: ['Mail.Send'],
        });

        return response.accessToken;
      } catch (popupError) {
        console.error('Error acquiring token interactively', popupError);
        return null;
      }
    } else {
      console.error('Error acquiring token silently', error);
      return null;
    }
  }
}

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { DashboardEmployee } from 'src/Models/EmployeeModels';
import { SquadGroup } from 'src/Models/SquadModels';
import { getAccessToken } from 'src/authConfig';
import { PostSquadList, SquadGroupService } from 'src/services/SquadGroupService';
import { SquadsContext } from 'src/utilities/SquadsContext';

import { useFilterContext } from '../activeEmployeesDashboard/context/FilterContext';
import { NarrowTableCell } from '../dashboardShared/NarrowTableCell';
import { useClientManagementFilterContext } from './context/ClientManagementFilterContext';

export interface AssignToSquadModalProps {
  open: boolean;
  onClose: () => void;
  selectedEmployees: DashboardEmployee[];
  resetSelectedEmployees: () => void;
  handleCheckBoxChecked: (employee: DashboardEmployee) => void;
}

const AssignToSquadModal: React.FC<AssignToSquadModalProps> = ({
  open,
  onClose,
  selectedEmployees,
  resetSelectedEmployees,
  handleCheckBoxChecked,
}) => {
  const [selectedSquadSquadGroup, setSelectedSquadGroup] = useState<SquadGroup>();
  const [availableSquadGroups, setAvailableSquadGroups] = useState<SquadGroup[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const { squad } = useContext(SquadsContext);
  const squadGroupService = new SquadGroupService();
  const { updateSquadData } = useClientManagementFilterContext();
  const { updateSquadData: updateActiveSquad } = useFilterContext();
  const [sendMail, setSendmail] = useState(false);
  const [token, setToken] = useState<string | null>(null);

  const getSquadGroups = async () => {
    var response = await squadGroupService.getSquadGroupsBySquadId(squad.squadId);
    setAvailableSquadGroups(response);
  };

  useEffect(() => {
    if (sendMail) {
      const getToken = async () => {
        const token = await getAccessToken();
        setToken(token);
      };
      getToken();
    }
  }, [sendMail]);

  const handleAssignEmployeesToSquad = async () => {
    if (selectedSquadSquadGroup) {
      var res: PostSquadList = {
        employeeIds: selectedEmployees.map((x) => x.employeeId),
        squadGroupId: selectedSquadSquadGroup?.groupId,
        sendEmail: sendMail,
      };
      var response = await squadGroupService.AddListOfEmployeesToSquadGroup(res, squad.squadId, token ?? '');
      if (response) {
        updateSquadData();
        updateActiveSquad();
        resetSelectedEmployees();
        handleModalClose();
      }
    }
  };

  const handleModalClose = () => {
    onClose();
  };

  useEffect(() => {
    if (selectedSquadSquadGroup) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedSquadSquadGroup]);

  useEffect(() => {
    getSquadGroups();
  }, []);

  const onSelectedSquadGroupChange = (value: any) => {
    setSelectedSquadGroup(value);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'md'}>
      <DialogTitle>Rotate Squad Group</DialogTitle>
      <DialogContent sx={{ minHeight: 200 }}>
        {availableSquadGroups ? (
          <Grid>
            <Autocomplete
              sx={{ marginTop: '10px' }}
              options={availableSquadGroups}
              getOptionLabel={(squadGroup: SquadGroup) => squadGroup.groupName}
              multiple={false}
              filterSelectedOptions
              value={selectedSquadSquadGroup || null}
              onChange={(event, value) => onSelectedSquadGroupChange(value)}
              renderInput={(params) => <TextField {...params} size="small" label="Select squad group" />}
            />
            <TableContainer component={Paper} sx={{ minHeight: 200, maxHeight: 300, marginTop: '20px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Assign Squad</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Current Squad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedEmployees.length > 0 ? (
                    selectedEmployees.map((employee) => (
                      <TableRow key={employee.employeeId}>
                        <NarrowTableCell>
                          <Checkbox
                            checked={selectedEmployees?.includes(employee)}
                            onChange={() => handleCheckBoxChecked && handleCheckBoxChecked(employee)}
                          />
                        </NarrowTableCell>
                        <NarrowTableCell>{employee.fullName}</NarrowTableCell>
                        <NarrowTableCell>{employee.groupNames[0] ?? '-'}</NarrowTableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No employees selected
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : (
          <CircularProgress />
        )}
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
          }}>
          <Typography sx={{ color: 'black' }}>Send onboarding email?</Typography>
          <Checkbox checked={sendMail} onChange={() => setSendmail(!sendMail)} />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleModalClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleAssignEmployeesToSquad}
          color="primary"
          disabled={isDisabled || selectedEmployees.length === 0 || !selectedSquadSquadGroup || (!token && sendMail)}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignToSquadModal;

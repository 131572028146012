import PluginEditor from '@draft-js-plugins/editor';
import { ContentState, EditorState, Entity, Modifier, SelectionState, convertFromRaw } from 'draft-js';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { editorStore } from '../feedbackComponent/FeedbackEditorStore';
import { useEntityContext } from '../feedbackComponent/context/EntityContext';

interface EditorContextProps {
  appendTextToEditorContentWithMentions: (mentionText: string, tagId?: number, tagType?: string, tagColor?: string, description?: string) => void;
  appendTextToEditorContent: (text: string, applyInlineStyle: boolean, setLocalState?: React.Dispatch<React.SetStateAction<EditorState>>) => void;
  resetEditorState: () => void;
  onEditorClick: () => void;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  editorState: EditorState;
  setEditFeedbackEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  editFeedbackEditorState: EditorState;
  ref: any;
  removeTextFromEditor: (textToRemove: string) => void;
}

const EditorContext = createContext<EditorContextProps>({
  appendTextToEditorContentWithMentions: () => {},
  appendTextToEditorContent: () => {},
  resetEditorState: () => {},
  onEditorClick: () => {},
  setEditorState: () => {},
  setEditFeedbackEditorState: () => {},
  editFeedbackEditorState: EditorState.createEmpty(),
  editorState: EditorState.createEmpty(),
  ref: null,
  removeTextFromEditor: () => {},
});

export const EditorProvider: React.FC = ({ children }) => {
  const { employee } = useEntityContext();
  const editorRef = useRef<PluginEditor>(null);

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editFeedbackEditorState, setEditFeedbackEditorState] = useState(EditorState.createEmpty());

  const currentEditorValue: string = useRecoilValue(editorStore(`editor${employee?.employeeId}`));

  useEffect(() => {
    const initialEditorStateJson = currentEditorValue;
    if (initialEditorStateJson && JSON.parse(initialEditorStateJson).blocks && JSON.parse(initialEditorStateJson).blocks[0].text !== '') {
      const initialEditorStateData = JSON.parse(initialEditorStateJson);
      const newEditorState = EditorState.createWithContent(convertFromRaw(initialEditorStateData));
      setEditorState(newEditorState);
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [employee]);

  const resetEditorState = useCallback(() => {
    setEditorState(EditorState.createEmpty());
  }, []);

  const onEditorClick = (): void => {
    editorRef.current?.focus();
  };

  const removeTextFromEditor = useCallback((textToRemove: string) => {
    const currentEditor = editorRef.current?.getEditorState();
    if (currentEditor && textToRemove) {
      const contentState = currentEditor.getCurrentContent();
      const blockMap = contentState.getBlockMap();

      const textToRemoveWithCompletedText = `${textToRemove} - Completed`;

      const block = blockMap.find((block) => block?.getText()?.includes(textToRemoveWithCompletedText)!);

      if (!block) {
        return;
      }

      const blockKey = block.getKey();
      const text = block.getText();
      const startOffset = text.indexOf(textToRemoveWithCompletedText);
      const endOffset = startOffset + textToRemoveWithCompletedText.length;

      const selection = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: startOffset,
        focusOffset: endOffset,
      });

      const newContentState = Modifier.removeRange(contentState, selection, 'backward');
      const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
      setEditorState(newEditorState);
    }
  }, []);

  const appendTextToEditorContentWithMentions = useCallback(
    (mentionText: string, tagId?: number, tagType?: string, tagColor?: string, description?: string) => {
      const currentEditor = editorRef.current?.getEditorState();
      if (currentEditor && mentionText && tagId && tagType && tagColor) {
        let newEditorState = currentEditor;

        const currentContentState = newEditorState.getCurrentContent();

        const entityKey = Entity.create('#mention', 'IMMUTABLE', {
          mention: {
            id: tagId,
            name: mentionText,
            type: tagType,
            color: tagColor,
          },
        });

        const blockMap = currentContentState.getBlockMap();
        const key = blockMap.last().getKey();
        const length = blockMap.last().getLength();
        const selection = new SelectionState({
          anchorKey: key,
          anchorOffset: length,
          focusKey: key,
          focusOffset: length,
        });

        const newContentState = Modifier.replaceText(currentContentState, selection, '#' + mentionText, undefined, entityKey);

        const contentStateWithSpace = Modifier.insertText(
          newContentState,
          newContentState.getSelectionAfter(),
          typeof description === 'undefined' ? ' ' : ' ' + description,
        );

        newEditorState = EditorState.push(newEditorState, contentStateWithSpace, 'insert-characters');
        newEditorState = EditorState.moveFocusToEnd(newEditorState);

        setEditorState(newEditorState);
      }
    },
    [],
  );

  const appendTextToEditorContent = useCallback(
    (text: string, applyInlineStyle: boolean = false, setLocalEditorState?: React.Dispatch<React.SetStateAction<EditorState>>) => {
      const currentEditor = editorRef.current?.getEditorState();
      if (currentEditor) {
        const currentContent = currentEditor.getCurrentContent();

        const blockMap = currentContent.getBlockMap();
        const key = blockMap.last().getKey();
        const length = blockMap.last().getLength();
        const selection = new SelectionState({
          anchorKey: key,
          anchorOffset: length,
          focusKey: key,
          focusOffset: length,
        });

        let modifiedContent: ContentState;

        if (applyInlineStyle) {
          const inlineStyle = currentEditor.getCurrentInlineStyle().add('UNDERLINE');
          const styledContentWithInsert = Modifier.insertText(currentContent, selection, `${text} - Completed`, inlineStyle);

          //adds space after inserting text to prevent styles from applying to next block of charecters.
          modifiedContent = Modifier.insertText(styledContentWithInsert, styledContentWithInsert.getSelectionAfter(), ' ');
        } else {
          modifiedContent = Modifier.insertText(currentContent, selection, text);
        }

        const editorWithInsert = EditorState.push(currentEditor, modifiedContent, 'change-inline-style');

        const newEditorState = EditorState.moveFocusToEnd(editorWithInsert);

        if (!setLocalEditorState) setEditorState(newEditorState);
        else setLocalEditorState(newEditorState);
      }
    },
    [],
  );

  return (
    <EditorContext.Provider
      value={{
        appendTextToEditorContentWithMentions,
        appendTextToEditorContent,
        resetEditorState,
        onEditorClick,
        setEditorState,
        editorState,
        setEditFeedbackEditorState,
        editFeedbackEditorState,
        ref: editorRef,
        removeTextFromEditor,
      }}>
      {children}
    </EditorContext.Provider>
  );
};

export const useEditor = () => {
  return useContext(EditorContext);
};

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { EmployeeLearningChecklistDTO } from 'src/Models/LearninpathModels';

import { BaseService } from './BaseService';
import { Employee, Feedback } from './FeedbackType';

export interface FeedbackGroup {
  createdDate: string;
  feedback: Feedback[];
}

export interface FeedbackGroupPayload {
  categoryId: string;
  sentimentId: number;
  description: string;
  squadId: number | string;
  groupId: number;
}

export interface FeedbackFollowUp {
  squadId: number;
  taskName: string;
  userId: number;
  dueDate: string;
  followUpNote: string;
  isCompleted: boolean;
}

export interface FeedbackExport {
  capturer: string;
  employeeName: string;
  sentiment: string;
  feedbackDescription: string;
  createdDate: Date;
  groupName: string;
  categoryName: string;
}

export interface FeedbackPayload {
  feedbackFollowUp?: FeedbackFollowUp | null;
  employeeId: number;
  categoryId: number;
  sentimentId?: number;
  description: string;
  createdBy: string;
  createdDate: string;
  followUp: boolean;
  squadId: number;
  assignedRole: string;
  phaseId?: number;
  updatedChecklists?: Array<EmployeeLearningChecklistDTO> | null;
}

export interface EmployeeOverviewDTO {
  employee: Employee;
  employeeFeedback: Feedback[];
  archivedEmployeeFeedback: Feedback[];
  isArchivedEmployee: boolean;
  maxArchivedPages: number;
  maxUnarchivedPages: number;
  archivedTotalCount: number;
  unArchivedTotalCount: number;
  totalFeedbackCount: number;
}

export interface OverviewSearchPayload {
  searchTerms: string[];
  categories: number[];
}

export class FeedbackService extends BaseService {
  constructor() {
    super('Feedback');
  }

  async upload(payload: FormData, config?: AxiosRequestConfig<any>) {
    return await this.httpService.post<unknown>(`/import`, payload, config);
  }

  async flag(id: string | number, squadId: number, body: any = {}): Promise<AxiosResponse<any, any>> {
    return this.httpService.patch(`/flag/${id}/${squadId}`, body);
  }

  async unflag(body: any, squadId: number): Promise<AxiosResponse<any, any>> {
    return this.httpService.post(`/unflag/feedbackID/${squadId}`, body);
  }

  async getByCategoryId(categoryId: string | number, squadId: string | number): Promise<AxiosResponse<any, any>> {
    return this.httpService.get(`/category/${categoryId}/${squadId}`);
  }

  async hideFromOverview(id: string | number): Promise<AxiosResponse<any, any>> {
    return this.httpService.patch(`/hideFromOverview/${id}`, {});
  }

  async getLatestFeedbackByEmployee(employeeId: number, squadId: number): Promise<Feedback[]> {
    return (await this.httpService.get(`/latest/${employeeId}/${squadId}`)).data;
  }

  async getGroupByDate(employeeId: string | number, squadId: string | number): Promise<AxiosResponse<any, any>> {
    return this.httpService.get(`/groupbydate/${employeeId}/${squadId}`);
  }

  async getGroupByDateItem(employeeId: string | number, squadId: string | number, feedbackId: string | number): Promise<AxiosResponse<any, any>> {
    return this.httpService.get(`/groupbydate/${employeeId}/${squadId}/${feedbackId}`);
  }

  async getEmployeeLearningPathFeedbackGroup(employeeLearningPathId: number): Promise<FeedbackGroup[]> {
    return (await this.httpService.get(`/learningpath/feedback/${employeeLearningPathId}`)).data;
  }

  async getGroupByDatePage(
    employeeId: string | number,
    squadId: string | number,
    pageNumber?: string | number,
    pageSize?: string | number,
    categoryId?: string | number,
  ): Promise<AxiosResponse<any, any>> {
    return this.httpService.get(`/groupbydatepage/${employeeId}/${squadId}/${pageNumber}/${pageSize}/${categoryId}`);
  }
  async getGroupfeedbackGroupByDate(groupId: string | number, squadId: string | number): Promise<AxiosResponse<any, any>> {
    return this.httpService.get(`/groupfeedbackgroupbydate/${groupId}/${squadId}`);
  }

  async getGroupfeedbackGroupByDatePage(
    groupId: string | number,
    squadId: string | number,
    pageNumber?: string | number,
    pageSize?: string | number,
    categoryId?: string | number,
  ): Promise<AxiosResponse<any, any>> {
    return this.httpService.get(`/groupfeedbackgroupbydatepage/${groupId}/${squadId}/${pageNumber}/${pageSize}/${categoryId}`);
  }

  async getWithFollowUp(squadId: string | number): Promise<AxiosResponse<any, any>> {
    return this.httpService.get(`/followUp/${squadId}`);
  }

  async getCertificationFeedback(certificationName: string, employeeId: number | string): Promise<Feedback[]> {
    return (await this.httpService.get(`/certification-feedback/${certificationName}/${employeeId}`)).data;
  }

  async createGroupFeedback(payload: FeedbackGroupPayload): Promise<AxiosResponse<any, any>> {
    return this.httpService.post(`/GroupFeedback`, payload);
  }

  async searchOverview(squadId: string | number, body: OverviewSearchPayload): Promise<AxiosResponse<Feedback[], any>> {
    return this.httpService.post(`/overview/${squadId}`, body);
  }

  async getFeedbackExportData(squadId: string | number, dateFrom: string | null, dateTo: string): Promise<AxiosResponse<FeedbackExport[], any>> {
    return (await this.httpService.get(`/feedback/export/${squadId}/${dateFrom}/${dateTo}`)).data;
  }

  async searchEmployeeOverview(
    squadId: string | number,
    body: OverviewSearchPayload,
    pageNumber: number,
    pageSize: number,
  ): Promise<AxiosResponse<EmployeeOverviewDTO[], any>> {
    const params = {
      pageNumber: pageNumber,
      pageSize: pageSize,
    };

    return this.httpService.post(`/overview/employeeOverview/${squadId}`, body, { params });
  }

  async getFeedbackByTagAndEmployeeId(tagId: number, employeeId: number, squadId: string): Promise<AxiosResponse<FeedbackGroup[], any>> {
    return this.httpService.get(`/GetByTagAndEmployeeId/${tagId}/${employeeId}/${squadId}`);
  }

  async searchArchivedEmployeeOverview(
    squadId: string | number,
    body: OverviewSearchPayload,
    pageNumber: number,
    pageSize: number,
  ): Promise<AxiosResponse<EmployeeOverviewDTO[], any>> {
    const params = {
      pageNumber: pageNumber,
      pageSize: pageSize,
    };

    return this.httpService.post(`/overview/employeeOverviewArchived/${squadId}`, body, { params });
  }

  async getFeedbackByEmployeeIdWithFilter(
    employeeId: number,
    squadId: string | number,
    category: string,
    grouped: boolean,
    followed: boolean,
  ): Promise<FeedbackGroup[]> {
    return (await this.httpService.get(`/feedback/${employeeId}/${squadId}?category=${category}&grouped=${grouped}&followed=${followed}`)).data;
  }

  async getEmployeeLearningPathFeedback(employeeLearningPathId: number): Promise<Feedback[]> {
    return (await this.httpService.get(`/learningpath/feedback/${employeeLearningPathId}`)).data;
  }
}

import { Avatar } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { isEqual } from 'lodash';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetProfilePicture } from 'src/hooks/useGetProfilePicture';

import EyeBadge from '../latestUpdates/EyeBadge';

interface ProfilePictureProps {
  detailsExpanded?: boolean;
  alt: string;
  src?: string;
  employee?: {
    fullName: string;
    jobTitle: string;
    emailAddress: string;
    employeeId: number;
    telephoneNumber?: string;
    isDeleted: boolean;
  };
  telephoneNumber?: string;
  emailAddress: string;
  className?: string;
  sx?: SxProps<Theme>;
  variant?: 'square' | 'rounded' | 'circular';
  showEyeBadge?: boolean;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  detailsExpanded = false,
  alt,
  src = '',
  employee,
  emailAddress,
  className,
  sx,
  variant,
  showEyeBadge,
}) => {
  const navigate = useNavigate();
  const { profilePictureImgSrc } = useGetProfilePicture(emailAddress, 120);

  const navigateToEmployeeProfile = (employeeId: number) => {
    if (showEyeBadge) {
      navigate(`/employee-profile/${employeeId}`);
    }
  };

  return (
    <EyeBadge show={showEyeBadge}>
      <Avatar
        data-testid="profile-picture"
        key={emailAddress}
        variant={variant}
        alt={alt}
        src={profilePictureImgSrc === '' ? src : profilePictureImgSrc}
        className={className}
        sx={{
          ...sx,
          cursor: showEyeBadge ? 'pointer' : '',
        }}
        onClick={() => navigateToEmployeeProfile(employee?.employeeId ?? -1)}
      />
    </EyeBadge>
  );
};

export default memo(ProfilePicture, (prevProps, nextProps) => isEqual(prevProps, nextProps));

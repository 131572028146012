import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import FlagIcon from '@mui/icons-material/Flag';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Divider, ListItemIcon, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useEntityContext } from 'src/components/feedbackComponent/context/EntityContext';
import { useFeedbackContext } from 'src/components/feedbackComponent/context/FeedbackContext';
import ConfirmArchive from 'src/components/shared/ConfirmArchive';
import { useSnackbar } from 'src/components/shared/SnackbarContext';
import { useToggle } from 'src/hooks/useToggle';
import { FeedbackService } from 'src/services/FeedbackService';
import { Feedback } from 'src/services/FeedbackType';
import { LoggedInUserContext } from 'src/utilities/LoggedInUserContext';
import { SquadsContext } from 'src/utilities/SquadsContext';

import ReasonModal from './FeedbackReasonModal';

const ITEM_HEIGHT = 48;
interface CardMenuProps {
  feedbackItem: Feedback;
  handleEdit: () => void;
  refreshData: () => void;
}

const feedbackService = new FeedbackService();

const CardMenu = ({ feedbackItem, handleEdit, refreshData }: CardMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openReason, toggleReason] = useToggle();
  const [isDelete, toggleDelete] = useToggle();
  const { openSnackbar } = useSnackbar();
  const squad = React.useContext(SquadsContext).squad;

  const handleOverviewClick = async () => {
    const isHidden = feedbackItem.hideFromOverview;
    feedbackService.hideFromOverview(feedbackItem.feedbackId).then(() => {
      getFeedbackItems(true);
      handleClose();
      !isHidden && openSnackbar('info', 'Feedback hidden from overview');
    });
  };
  const handleFlagClick = () => {
    handleClose();
    if (feedbackItem.followUp) return toggleReason();
    flagFeedback();
  };
  const handleEditClick = () => {
    handleClose();
    handleEdit();
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { getEmployees } = useEntityContext();
  const { getFeedbackItems } = useFeedbackContext();

  const flagFeedback = async () => {
    await feedbackService.flag(feedbackItem.feedbackId, +squad.squadId);

    getFeedbackItems(true);
    getEmployees();
  };

  const handleDelete = async () => {
    setAnchorEl(null);
    await feedbackService.delete(feedbackItem.feedbackId.toString()).then(refreshData);
    getFeedbackItems(true);
    getEmployees();
  };

  const handleUnflag = (reason: string) => {
    handleClose();
    flagFeedback();
    feedbackService.unflag(
      {
        feedbackId: feedbackItem.feedbackId,
        employeeSquadId: feedbackItem.employee?.squadId,
        unflagReason: reason,
      },
      +squad.squadId,
    );
    getEmployees();
  };

  const { loggedInUser } = React.useContext(LoggedInUserContext);
  const isEditable = feedbackItem.createdByEmail.trim().toLowerCase() === loggedInUser.trim().toLowerCase();
  if (isDelete)
    return (
      <ConfirmArchive
        cancelArchiveClicked={() => {
          toggleDelete();
          setAnchorEl(null);
        }}
        confirmArchiveClicked={handleDelete}
      />
    );

  return (
    <>
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}>
          <MenuItem onClick={handleFlagClick} disabled={!isEmpty(feedbackItem.unflaggedFeedback)}>
            <ListItemIcon>
              <FlagIcon fontSize="small" />
            </ListItemIcon>
            {feedbackItem.followUp ? <ListItemText>UNFLAG</ListItemText> : <ListItemText>FLAG</ListItemText>}
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              toggleDelete(true);
            }}>
            <ListItemIcon>
              <DeleteOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>DELETE</ListItemText>
          </MenuItem>

          {isEditable && <Divider />}
          {isEditable && (
            <MenuItem onClick={handleEditClick}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>EDIT</ListItemText>
            </MenuItem>
          )}
          <Divider />
          <MenuItem onClick={handleOverviewClick}>
            <ListItemIcon>
              {feedbackItem.hideFromOverview ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
            </ListItemIcon>
            OVERVIEW
          </MenuItem>
        </Menu>
      </div>
      <ReasonModal
        open={openReason}
        onClose={() => {
          toggleReason(false);
        }}
        onSave={handleUnflag}
      />
    </>
  );
};
export default CardMenu;

import { DashboardEmployee } from 'src/Models/EmployeeModels';

import { BaseService } from './BaseService';

export interface User {
  userId: number;
  fullName: string;
  emailAddress: string;
  isActive: boolean;
  createdDate: string;
  updatedDate: string;
  isTenantManager: boolean;
  squadIds: number[];
}

export interface SquadLeader {
  squadLeaderId: number;
  squadId: number;
  userId: number;
  isDeleted: boolean;
  isAdmin: boolean;
  isSuperUser: boolean;
  user: User;
  categoryId?: number;
}

export interface User {
  userId: number;
  fullName: string;
  emailAddress: string;
  isActive: boolean;
  createdDate: string;
  updatedDate: string;
  isTenantManager: boolean;
  squadIds: number[];
}

export interface SquadLeader {
  squadLeaderId: number;
  squadId: number;
  userId: number;
  isDeleted: boolean;
  isAdmin: boolean;
  isSuperUser: boolean;
  user: User;
}

export type GroupLeader = {
  groupLeaderId: number;
  squadLeaderId: number;
  squadGroupId: number;
  isOwner: boolean;
  isDeleted: boolean;
  squadLeader: SquadLeader;
  squadGroup: SquadGroup;
};

export type PostSquadList = {
  employeeIds: number[];
  squadGroupId: number;
  sendEmail: boolean;
};

export type SquadGroup = {
  groupId: number;
  squadId: number;
  isActive: boolean;
  groupName: string;
  groupLeaders: GroupLeader[];
  groupMembers: SquadMember[];
  groupOwner: GroupLeader;
  emailAddress?: string;
};

export type SquadMember = {
  groupMemberId: number;
  employeeSquadId: number;
  employeeId: number;
  squadGroupId: number;
  isDeleted: boolean;
  employeeFullName: string;
};

export type RelatedSquadMember = {
  groupMemberId: number;
  employeeSquadId: number;
  employeeId: number;
  squadGroupId: number;
  isDeleted: boolean;
  emailAddress: string;
  employeeFullName: string;
};

export interface GenerateSquadGroupResponse {
  squadGroupId: number;
  groupName: string;
  emailAddress: string;
  fullName: string;
}

export interface GetActiveGroupMemberResponse {
  key: number;
  groupMemberId: number;
  employeeSquadId: number;
  squadGroupId: number;
  groupName: string;
  emailAddress: string;
  fullName: string;
  statusName: string;
  daysOnSquad: number;
}

export type IncommingSquadGroupGraphData = {
  squadName: string;
  countEmployees: number;
};

export interface RotationHistoryData {
  responsiblePerson: string;
  activity: string;
  fileName: string;
  actionDate: Date;
  numberOfGroups: number;
  numberOfEmployees: number;
  rotationHistoryId: string;
}

export interface UnassignedMember {
  employeeId: number;
  emailAddress: string;
  fullName: string;
  statusName: string;
  daysOnSquad: number;
}

export class SquadGroupService extends BaseService {
  constructor() {
    super('SquadGroup');
  }

  async getSquadGroupsBySquadId(squadId: number | string, squadLeaderId?: number | string): Promise<SquadGroup[]> {
    const response = await this.httpService.get(`/squadGroups/${squadId}${squadLeaderId ? `/${squadLeaderId}` : ''}`);
    return response.data as SquadGroup[];
  }

  async getSquadGroupMembersBySquadGroupId(squadId: number | string, squadGroupId: number | string): Promise<SquadMember[]> {
    const response = await this.httpService.get(`/groupMembers/${squadId}/${squadGroupId}`);
    return response.data as SquadMember[];
  }

  async getRelatedSquadGroupMembersBySquadGroupId(squadId: number | string, squadGroupId: number | string): Promise<RelatedSquadMember[]> {
    const response = await this.httpService.get(`/groupMembers/${squadId}/${squadGroupId}`);
    return response.data as RelatedSquadMember[];
  }

  async addGroupLeaderToGroup(squadLeaderId: number | string, groupId: number | string) {
    return (await this.httpService.post(`/groupLeaders/${squadLeaderId}/${groupId}`, {})).data;
  }

  async getGroupsByLeaderId(squadLeaderId: number | string) {
    return await this.httpService.get(`groups/${squadLeaderId}`);
  }

  async removeGroupsByGroupId(groupId: number | string) {
    return await this.httpService.patch(`/group/remove/${groupId}`, groupId);
  }

  async updateGroups(body: SquadGroup[]) {
    return await this.httpService.patch(`/group`, body);
  }

  async getGroupLeaderByGroupId(groupId: number | string) {
    return await this.httpService.get(`groupsLeaders/${groupId}`);
  }

  async getGroupDataForSquad(squadId: string) {
    return (await this.httpService.get(`/generate/${squadId}`)).data as GenerateSquadGroupResponse[];
  }

  async getActiveGroupMemberDataForSquad(squadId: string) {
    return (await this.httpService.get(`/activeGroupMembers/${squadId}`)).data as GetActiveGroupMemberResponse[];
  }

  async getActiveUnassignedMembers(squadId: string) {
    return (await this.httpService.get(`/activeUnassignedGroupMembers/${squadId}`)).data as UnassignedMember[];
  }

  async getGroupRotationHistoryBySquadId(squadId: string) {
    return (await this.httpService.get(`/groupRotation/${squadId}`)).data as RotationHistoryData[];
  }

  async restore(rotationHistoryId: string, squadId: string): Promise<RotationHistoryData> {
    return (await this.httpService.patch(`/restore/${rotationHistoryId}/${squadId}`, {})).data;
  }

  async AddListOfEmployeesToSquadGroup(model: PostSquadList, squadId: string, token: string): Promise<any> {
    return (
      await this.httpService.post(`/employee/group/add/${squadId}`, model, {
        headers: {
          Accept: `Bearer ${token}`,
        },
      })
    ).data;
  }

  async GetIncommingSquadGroupGraphData(squadId: string): Promise<IncommingSquadGroupGraphData[]> {
    return (await this.httpService.get(`/squadgraph/incomming/${squadId}`)).data;
  }

  async GetIncommingEmployeesBySquadGroup(squadId: string, squadGroupName: string): Promise<DashboardEmployee[]> {
    return (await this.httpService.get(`/squadgroup/employees/${squadId}/${squadGroupName}`)).data;
  }

  async GetctiveSquadGroupGraphData(squadId: string, shouldFetchAllEmployees: boolean): Promise<IncommingSquadGroupGraphData[]> {
    return (await this.httpService.get(`/squadgraph/active/${squadId}/${shouldFetchAllEmployees}`)).data;
  }

  async GetActiveEmployeesBySquadGroup(squadId: string, squadGroupName: string, shouldShowAllEmployees: boolean): Promise<DashboardEmployee[]> {
    return (await this.httpService.get(`/squadgroup/active/employees/${squadId}/${squadGroupName}/${shouldShowAllEmployees}`)).data;
  }
}
